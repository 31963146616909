@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  src:
    local('Open Sans'),
    local('OpenSans'),
    url("/fonts/open-sans.woff2") format("woff2"),
    url("/fonts/open-sans.woff") format("woff");
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local('Open Sans Bold'),
    local('OpenSans-Bold'),
    url("/fonts/open-sans-bold.woff2") format('woff2'),
    url("/fonts/open-sans-bold.woff") format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src:
    local('Open Sans Cond Light'),
    local('OpenSans-CondensedLight'),
    url("/fonts/open-sans-cond.woff2") format('woff2'),
    url("/fonts/open-sans-cond.woff") format('woff');
}

@font-face {
  font-family: 'Alegreya SC';
  font-style: normal;
  font-weight: 400;
  src:
    url('/fonts/AlegreyaSC-Regular.ttf') format('ttf')
    url('/fonts/AlegreyaSC-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Alegreya SC';
  font-style: normal;
  font-weight: 700;
  src:
    url('/fonts/AlegreyaSC-Bold.ttf') format('ttf'),
    url('/fonts/AlegreyaSC-Bold.woff2') format('woff2'),
}
