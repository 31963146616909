/* TX Style Variables */
@import '../colors';

$primary: #44413f;
$secondary: #891906;
$summary: transparent;
$light: #e3ddda;

$font-size-base:                    .9rem !default;
$font-family-base:                  'Open Sans', sans-serif;
$headings-font-family:              'Alegreya SC', sans-serif;

/* Primary Page-Styling Variables */
$body-bg:                           #555;
$body-color:                        #222;
$link-color:                        $secondary;

$header-bg:                         $light;
$navbar-bg:                         $light;

$footer-bg:                         $white;
$footer-nav-link-color:             $body-color;

$navbar-light-color:                $secondary;

$dropdown-bg:                       #555;
$dropdown-hover-bg:                 none;
$dropdown-divider-bg:               #777;

$dropdown-link-color:               $white;
$dropdown-link-hover-color:         $white;
$dropdown-link-hover-bg:            $primary;

$mobile-navbar-bg:                  #222;
$static-page-header-color:          $secondary;

$static-page-div-color-primary:     #f6a354;
$static-page-div-color-secondary:   #3b60a6;


/* Home Page Styling Variable */
$jumbotron-bg:                      hsla(0,0%,100%,.7);


//Process Page Styling
$process-page-themes: (
  "claim": $light,
  "holder": $light
) !default;

$pp-color:                          $primary;
$btn-action-bg:                     $primary;
$btn-home-bg:                       $primary;
$btn-nav-bg:                        $primary;
$btn-pop-bg:                        $secondary;
$btn-mbl-color:                     $primary;


/* Image Size & Image Source Variables */
$banner-image-url:                  url('/images/banner.png');
$body-background-image-url:         url('/images/bg-texture.png');

$claim-header-logo-url:             url('/images/process_logo.png');
$claim-header-logo-width:           190px;
$claim-header-logo-height:          70px;

$holder-header-logo-url:             $claim-header-logo-url;
$holder-header-logo-width:           $claim-header-logo-width;
$holder-header-logo-height:          $claim-header-logo-height;

$home-logo-url:                     url('/images/main_logo.png');
$home-logo-width:                   420px;
$home-logo-height:                  70px;
$home-logo-resize-value-map:        (width: 242px,
                                     height: 40px,
                                     background-image: $home-logo-url);

$treasurer-logo-url:                $home-logo-url;
$treasurer-logo-width:              $home-logo-width;
$treasurer-logo-height:             $home-logo-height;
$treasurer-logo-resize-value-map:   $home-logo-resize-value-map;

$footer-logo-url:                   url('/images/footer_logo.png');
$footer-logo-width:                 300px;
$footer-logo-height:                30px;
$footer-logo-resize-md-value-map:   (width: 290px,
                                     height: 30px,
                                     background-image: $footer-logo-url);
$footer-logo-resize-value-map:      (width: $footer-logo-width,
                                     height: $footer-logo-height,
                                     background-image: $footer-logo-url);

$claimform-image-url:               url('/images/CDU_claim_form_sample.png');

$icon-email-url:                    url('/images/gcp.png');
$icon-facebook-url:                 url('/images/facebook.png');
$icon-instagram-url:                url('/images/instagram.png');
$icon-twitter-url:                  url('/images/twitter.png');
$icon-youtube-url:                  url('/images/youtube.png');
$icon-linkedin-url:                 none;

$social-icon-width:                 25px;
$social-icon-height:                25px;


#textnet-div-primary {
  background-color: $static-page-div-color-primary;
}

#textnet-div-secondary {
  background-color: $static-page-div-color-secondary;
}
